<template>
  <div class="p-5">
    <Produtos />
  </div>
</template>

<script>

import Produtos from '../Configuracoes/Cadastros/Produtos.vue';
export default {
 components:{

    Produtos
},
 props:{

 },
 mounted(){

 },
 data(){ 
    return {

    }
 },
 watch:{

 },
 computed:{

 },
 methods:{

 }
}
</script>

<style lang="scss" scoped>

</style>